/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-06 14:18:55
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-06 14:21:42
 */
import axios from "../../axios"
const obj = {
    getGoodsList:(params)=>{
        return axios.get("/getParmas",params);
    }
}
export default obj
/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-31 16:01:08
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-09 14:55:33
 */
const routes = [
    {
        path:"/",
        name:"Login",
        component: resolve => require(['@/views/login.vue'], resolve),
    },
    {
      path: "/home",
      name: "Home",
      component: resolve => require(['@/views/Home.vue'], resolve),
      children:[
        {
            path:"/homePage",
            name:"HomePage",
            component: resolve => require(['@/views/homePage/homePage.vue'], resolve),
        },
        {
          path:"/tianjinjb",
          name:"Tianjinjb",
          component: resolve => require(['@/views/tianjin/tianjin.vue'], resolve),
        },
        {
            path:"/cutPhoto/cutPhoto",
            name:"CutPhoto",
            component: resolve => require(['@/views/cutPhoto/cutPhoto.vue'], resolve),
        },
        {
          path:"/goodsList/goodsList",
          name:"GoodsList",
          component: resolve => require(['@/views/goods/goodsList.vue'], resolve),
        },
        {
          path:"/cartogram/index",
          name:"Cartogram",
          component: resolve => require(['@/views/cartogram/index.vue'], resolve),
        },
        {
          path:"/statusCode/statusCode",
          name:"StatusCode",
          component: resolve => require(['@/views/statusCode/statusCode.vue'], resolve),
        },
        {
          path:"/statement/statement",
          name:"statement",
          component: resolve => require(['@/views/statement/statement.vue'], resolve),
        },
        {
          path:"/map",
          name:"Map",
          component: resolve => require(['@/views/map/map.vue'], resolve),
        },
        {
            path:"/deviceInput",
            name:"DeviceInput",
            component: resolve => require(['@/views/deviceInput/deviceInput.vue'], resolve),
          }
      ]
    }
  ];
export default routes
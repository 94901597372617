/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-31 17:01:16
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-22 12:27:26
 */
import axios from "../../axios.js"
const obj = {
    getGoodsList:(params)=>{
        return axios.get("/aaaa",params);
    }
}
export default obj
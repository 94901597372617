/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-31 17:04:53
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-09 14:39:38
 */
import homePageApi from "./pageApi/homePage/homePage"
import tianjinApi from "./pageApi/tianjin/tianjin.js"
import deviceInput from "./pageApi/deviceInput/deviceInput.js"
export default {
    homePageApi,
    tianjinApi,
    deviceInput
}
/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2022-08-09 14:38:37
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-09 14:38:37
 */
import axios from "../../axios.js"
const obj = {
    login:(params)=>{
        return axios.post("/reportform_login_new",params);
    }
}
export default obj
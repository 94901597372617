/*
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2022-08-09 14:31:28
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2022-08-09 14:37:23
 */
const ENV = process.env.NODE_ENV;
// 初始化
const baseUrl = ENV == 'development' ? "https://test.cloud.background.adasplus.com/" : "https://test.cloud.background.adasplus.com/";
export default baseUrl;